/**
 * 生产分析
 */
import req from './index.js'

export const GetEquipmentInforStandbyAnalysisList = param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/GetEquipmentInforStandbyAnalysisList',param) }; 
export const GetEquipmentInforStandbyAnalysisBarByMonth = param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/GetEquipmentInforStandbyAnalysisBarByMonth',param) }; 
export const GetEquipmentInforStandbyAnalysisBarByDay = param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/GetEquipmentInforStandbyAnalysisBarByDay',param) }; 
export const GetEquipmentInforStandbyAnalysisPieChartCountBystandByCause= param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/GetEquipmentInforStandbyAnalysisPieChartCountBystandByCause',param) };
export const GetEquipmentInforStandbyAnalysisPieChartSumBystandByCause= param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/GetEquipmentInforStandbyAnalysisPieChartSumBystandByCause',param) };
// 
export const standbyAnalysisOrganizationSelect= param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/StandbyAnalysisOrganizationSelect',param) };//待机分析组织选择
// 待机分析-重要程度下拉选
export const standbyAnalysisAlarmLevelSelect= param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/StandbyAnalysisAlarmLevelSelect',param) };
// 待机分析-设备类型下拉选
export const standbyAnalysisAlarmTypeSelect= param => { return req.post('EMS.Thing.Api.StandbyAnalysis/Services/StandbyAnalysisAlarmTypeSelect',param) };


//停机分析
export const getEquipmentInforStopAnalysisList= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/GetEquipmentInforStopAnalysisList',param) }; 
export const getEquipmentInforStopAnalysisBarByDay= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/GetEquipmentInforStopAnalysisBarByDay',param) };
export const getEquipmentInforStopAnalysisBarByMonth= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/GetEquipmentInforStopAnalysisBarByMonth',param) };
export const getEquipmentInforStopAnalysisPieChartSumBystandByCause= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/GetEquipmentInforStopAnalysisPieChartSumBystandByCause',param) };
export const getEquipmentInforStopAnalysisPieChartCountBystandByCause= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/GetEquipmentInforStopAnalysisPieChartCountBystandByCause',param) };
export const stopAnalysisOrganizationSelect= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/StopAnalysisOrganizationSelect',param) };
// 停机分析-重要程度下拉选
export const stopAnalysisAlarmLevelSelect= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/StopAnalysisAlarmLevelSelect',param) };
// 停机分析-设备类型下拉选
export const stopAnalysisAlarmTypeSelect= param => { return req.post('EMS.Thing.Api.StopAnalysis/Services/StopAnalysisAlarmTypeSelect',param) };


//生产分析
export const manufactureAnalysisTable= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisGetManufactureAnalysisTable',param) }; // 表格
export const manufactureAnalysisTrend= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisGetManufactureAnalysisTrend',param) }; // 曲线
export const manufactureAnalysisKpiSelect= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisKpiSelect',param) }; // kpi下拉框
export const manufactureAnalysisOrganizationSelect= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisOrganizationSelect',param) }; // 选择设备
export const manufactureAnalysisWorkpieceSelect= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisWorkpieceSelect',param) }; // 选择工件型号
