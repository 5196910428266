<!-- 生产分析-生产分析 -->
<template>
  <div class="page_container" v-loading="loading">
    <div class="box_head1">
      多设同工件节拍分析
    </div>
    <div class="flex flex_column bgc_FFFFFF">
      <!-- 选择页面 -->
      <!-- <div class="flex alarmAnalysisMargin">
        <div class="mg_right_10 box_1" :class="tabIndex==1?'box_1_active':''" @click="changeTab(1)">设备维度</div>
        <div class="mg_right_10 box_1 " :class="tabIndex==2?'box_1_active':''" @click="changeTab(2)">设备型号维度</div>
        <div class="mg_right_10 box_1" :class="tabIndex==3?'box_1_active':''" @click="changeTab(3)">设备品牌维度</div>
      </div> -->
      <!-- select下拉框 -->
      <div class="flex alarmAnalysisMargin justify_between">
        <div class="flex align_center fn_size14">
          <!-- width_190 -->
          <el-cascader class="mg_right_10 " ref="demoCascader" v-model="organizationValue" :options="orgTreeList"
            :props="defaultProps" @change="handleChange" :show-all-levels=false collapse-tags clearable>
          </el-cascader>
          <el-select v-model="dateType" placeholder="时间维度" class="width_190 mg_right_10">
            <el-option v-for="item in dateTypeArr" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="kpieceSelectionValue" placeholder="程序号" class="width_190 mg_right_10">
            <el-option v-for="item in kpieceSelection" :key="item.workpieceProgramNumber"
              :label="item.workpieceProgramName" :value="item.workpieceProgramNumber">
            </el-option>
          </el-select>
          <div class="mg_left_20">时间范围：</div>
          <el-date-picker v-model="planTimeValue" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions" @change="changePlanTimeValue">
          </el-date-picker>

          <el-button type="primary" class="mg_left_10 dimensionSelectButton flex align_center justify_center"
            @click="queryByParam">查询</el-button>
        </div>
      </div>
      <!-- table -->
      <div class=" alarmAnalysisMargin fn_size14">
        <el-table :data="tableData" tooltip-effect="dark" class="" height="500" v-loading="tabelLoading">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="equipmentID" label="设备ID" width=""></el-table-column>
          <el-table-column prop="equipmentName" label="设备名称" width=""></el-table-column>
          <el-table-column prop="currentPlanOutputTotal" label="计划产量" width=""></el-table-column>
          <el-table-column prop="currentQualifiedOutputTotal" label="合格产量" width=""></el-table-column>
          <el-table-column prop="currentActualOutputTotal" label="实际产量" width=""></el-table-column>
          <el-table-column prop="avg" label="平均节拍" width=""></el-table-column>
          <el-table-column prop="max" label="单件最大时间" width=""></el-table-column>
          <el-table-column prop="min" label="单件最小时间" width=""></el-table-column>
          <el-table-column prop="indexCycle" label="指标周期" width="" show-overflow-tooltip></el-table-column>
          <el-table-column prop="workpieceProgramName" label="程序名" width=""></el-table-column>
        </el-table>
      </div>
      <div class="flex alarmAnalysisMargin">
        <el-select v-model="typeKpi" placeholder="KPI选项" class="dimensionTrendSelect" @change="kpiChange">
          <el-option v-for="item in kpiSelection" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- echarts图 -->
      <div class="diy_div alarmAnalysisMargin">
        <div id="chartColumn" class="height_100 width_100"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  import {
    manufactureAnalysisTable,
    manufactureAnalysisTrend,
    manufactureAnalysisKpiSelect,
    manufactureAnalysisWorkpieceSelect,
    manufactureAnalysisOrganizationSelect,
  } from "@/api/api_productAnalysis";
  export default {
    data() {
      return {
        tableParam: {},
        // tabIndex: 1,
        radio: "设备维度",
        permission: {},
        options: [{
            value: 1,
            label: "日",
          },
          {
            value: 2,
            label: "月",
          }
        ],
        tableData: [],
        multipleSelection: [],
        kpiSelection: [],
        dateType: 1,
        dateTypeArr: [{
          value: 1,
          label: '日'
        }, {
          value: 2,
          label: '月'
        }],
        kpieceSelectionValue: '',
        kpieceSelection: [],
        organizationValue: [],
        organizationP: [],
        orgTreeList: [],
        typeKpi: '',
        // planTimeValue: null, 
        planTimeValue: [new Date(new Date().toLocaleDateString()), new Date()],
        standardArr: [],
        time: [new Date("2021-05-01"), new Date("2021-05-30")],
        currentData: [],
        standardData: [],
        loading: true,
        defaultProps: {
          label: 'Name',
          value: 'ID',
          expandTrigger: 'hover',
          multiple: true, //多选
          emitPath: false, //只返回选中节点的值
          // checkStrictly: true //多选任一级
        },

        tabelLoading:true,
      };
    },
    mounted() {
      if (localStorage.getItem('permission') != null) {
        this.permission = JSON.parse(localStorage.getItem('permission'))
      }
      // this.manufactureAnalysisTable();
      // this.manufactureAnalysisTrend();
      // this.drawShape();
      this.manufactureAnalysisKpiSelect();
      this.manufactureAnalysisOrganizationSelect();
    },

    filters: {
      dateFilter: function (val) {
        return (
          new Date(val).toLocaleDateString() +
          " " +
          new Date(val).toTimeString().substring(0, 8)
        );
      }
    },

    methods: {
      // 时间范围发生变化后，需要重新调一下程序号的接口
      changePlanTimeValue() {
        this.kpieceSelectionValue ='';
        this.manufactureAnalysisWorkpieceSelect()
      },
      // changeTab(id) {
      //   this.tabIndex = id;
      // },
      // handleSelectionChange() {},
      queryByParam() {
        if (this.organizationValue.length == 0) {
          this.currentData = this.standardData;
        }
        this.manufactureAnalysisTable();
        this.manufactureAnalysisTrend();
      },
      //生产分析表
      async manufactureAnalysisTable() {
        this.tabelLoading = true;
        var tableParam = {
          organization: {
            "EquipmentID": this.currentData
          },
          dateType: this.dateType,
          programNumber: this.kpieceSelectionValue,
          permission: this.permission
        }
        if (this.planTimeValue != null) {
          tableParam.startDate = this.planTimeValue[0]
          tableParam.endDate = this.planTimeValue[1]
        }
        // else {
        //   tableParam.startDate = this.time[0]
        //   tableParam.endDate = this.time[1]
        // }
        const tableRes = await manufactureAnalysisTable(tableParam);
        this.tableData = tableRes.data;
        this.loading = false;
        this.tabelLoading = false;
      },
      // 选择工件型号
      async manufactureAnalysisWorkpieceSelect() {
        var tableParam = {
          organization: {
            "EquipmentID": this.currentData
          },
          // startDate: this.time[0],
          // endDate: this.time[1],
          permission: this.permission,
          dateType: this.dateType
        }
        if (this.planTimeValue != null) {
          tableParam.startDate = this.planTimeValue[0]
          tableParam.endDate = this.planTimeValue[1]
        }
        const kpieceRes = await manufactureAnalysisWorkpieceSelect(tableParam);
        this.kpieceSelection = kpieceRes.data
        for (var i = 0; i < this.kpieceSelection.length; i++) {
          this.kpieceSelectionValue = this.kpieceSelection[i].workpieceProgramNumber;
          break
        }
        // this.kpieceSelectionValue = this.kpieceSelection[0].workpieceProgramNumber;
        this.manufactureAnalysisTable();
        this.manufactureAnalysisTrend();
        // this.loading = false;
        // kpieceRes.data.forEach(element => {
        //   this.kpieceSelection.push({
        //     value: element.workpieceProgramNumber,
        //     label: element.workpieceProgramName
        //   });
        // })
      },
      // kpi下拉选 
      async manufactureAnalysisKpiSelect() {
        var param = {
          permission: this.permission,
        }
        const selectKpiRes = await manufactureAnalysisKpiSelect(param);
        selectKpiRes.data.forEach(element => {
          this.kpiSelection.push({
            value: element.value,
            label: element.name
          });
        })
      },
      // 选择设备
      async manufactureAnalysisOrganizationSelect() {
        var param = {
          permission: this.permission,
        }
        const res = await manufactureAnalysisOrganizationSelect(param);
        this.orgTreeList = res.data.Tree;
        this.standardArr = res.data.organizationPermissionAll;
        for (var i = 0; i < this.standardArr.length; i++) {
          if (this.standardArr[i].Type == 'E') {
            // this.organizationValue = this.standardArr[i].ID;//
            this.currentData = this.standardArr[i].ID;
            this.standardData = this.standardArr[i].ID;
          }
        }
        this.manufactureAnalysisWorkpieceSelect();
        // setTimeout(() => {
        //   this.manufactureAnalysisTable();
        //   this.manufactureAnalysisTrend();
        // },100)

        // this.organizationP = res.data.organizationPermission;
        // this.organizationValue = res.data.organizationPermission[0].ID;
      },
      // 表格
      // async manufactureAnalysisOrganizationSelect() {
      //   var param = {
      //     permission: this.permission,
      //   }
      //   const res = await manufactureAnalysisOrganizationSelect(param);
      //   this.orgTreeList = res.data.Tree;
      //   this.organizationP = res.data.organizationPermission;
      //   this.organizationValue = res.data.organizationPermission[0].ID;
      // },
      // 曲线
      async manufactureAnalysisTrend() {
        this.chartColumn = echarts.init(document.getElementById("chartColumn"));
        this.chartColumn.showLoading({
          text: '加载中，请稍候...',
          // maskColor: 'rgba(3,3,8,0.5)',
          // textColor: '#fff600'
        });
        var trendParam = {
          organization: {
            "EquipmentID": this.currentData
          },
          dateType: this.dateType,
          programNumber: this.kpieceSelectionValue,
          permission: this.permission
        }
        if (this.typeKpi != '') {
          trendParam.typeKpi = this.typeKpi
        }
        if (this.planTimeValue != null) {
          trendParam.startDate = this.planTimeValue[0]
          trendParam.endDate = this.planTimeValue[1]
        }
        // else {
        //   trendParam.startDate = this.time[0]
        //   trendParam.endDate = this.time[1]
        // } 
        const trendRes = await manufactureAnalysisTrend(trendParam);
        console.log(trendRes);
        var line = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
          },
          legend: [],
          series: []
        };
        for (var i = 0; i < trendRes.data.length; i++) {
          var testData = {
            name: '',
            type: 'line',
            data: []
          }
          line.xAxis.data = trendRes.data[0].time;
          testData.name = trendRes.data[i].name;
          testData.data = trendRes.data[i].list;
          line.series.push(testData);
          line.legend.push(trendRes.data[i].name)
        }
        console.log(line)
        this.chartColumn = echarts.init(document.getElementById("chartColumn"));
        echarts.init(document.getElementById('chartColumn')).dispose(); //销毁echarts
        this.drawShape(line)
      },
      // kpi下拉选选中后调接口查数据更改曲线图
      kpiChange() {
        this.manufactureAnalysisTrend();
      },
      handleChange() {
        console.log(this.organizationValue)
        this.currentData = this.organizationValue
        // const obj = this.$refs['demoCascader'].getCheckedNodes()
        // console.log(obj);
        // // var testDate = [];
        // // var currentData = {
        // //   Type: [],
        // //   ID: []
        // // };
        // for (var i = 0; i < obj.length; i++) {
        //   // console.log(obj[i].data)
        //   var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
        //   // console.log(authorityadmin)
        //   if (authorityadmin.length == 0) {
        //     this.$message({
        //       message: '警告哦，您没有权限选择此项',
        //       type: 'warning'
        //     });
        //     this.organizationValue = []; //清空选项
        //     // // this.$refs['demoCascader'].clearCheckedNodes()
        //     return
        //   }rrentData.ID.push(obj[i].value)
        //   // }
        // }
        // // console.log(currentData)
        // this.currentData = this.organizationValue
        // this.currentData = currentData.ID
      },
      drawShape(data) {
        /**维度趋势选择 */
        this.chartColumn = echarts.init(document.getElementById("chartColumn"));
        this.chartColumn.setOption({
          tooltip: {
            // trigger: 'axis'
          },
          legend: {
            x: "center",
            y: "bottom",
            bottom: "3%", //距离下边距
            data: data.legend,
            type: 'scroll',
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "14%",
            top: '5%',
            containLabel: true,
          },
          xAxis: data.xAxis,
          yAxis: {
            type: "value",
            // min: 0, //y轴的最小值
            // // max: 30, //y轴最大值
            // interval: 10, //值之间的间隔
            // axisLine: {
            //   show: false,
            //   lineStyle: {
            //     color: "#869da7",
            //   },
            // },
            // axisLabel: {
            //   interval: 0,
            //   color: "#808080",
            //   fontSize: 12,
            // },
          },
          series: data.series
        });
        this.chartColumn.hideLoading()
      },
    },
  };
</script>

<style scoped>
  .mg_right_11 {
    margin-right: 11px;
  }

  .box_1 {
    width: 120px;
    height: 36px;
    background: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    /* border: 1px solid #CCDBE5; */
  }

  .box_1_active {
    background: #009BFD;
    color: #FFFFFF;
  }

  .alarmAnalysisHeader {
    /* width: 1862px; */
    height: 40px;
    padding-left: 20px;
    background-color: #e2f0fe;
    border: 1px solid #ccdbe5;
    font-size: 16px;
    font-family: Source Han Sans SC;
  }

  .dimensionTrendSelect {
    width: 160px;
    height: 36px;
  }

  .dimensionTrendSelect2 {
    width: 150px;
    height: 36px;
  }

  .diy_div {
    border: 100px;
    /* width: 1777px; */
    height: 400px;
    /* margin-top: 200px; */
  }

  .dimensionTrendSelect2 {
    width: 150px;
    height: 36px;
  }

  .dimensionSelectButton {
    width: 110px;
    height: 36px;
    background: #009bfd;
  }

  .alarmAnalysisMargin {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
  }
</style>

<style lang="less" scoped>
</style>